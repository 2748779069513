import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import config from '../config';
import mouse from '../assets/mouse_dark.png';
import { LinkContainer } from 'react-router-bootstrap';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('username', username);
        setUser({ username });
        navigate('/projects');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="limitmaxwidth">
      <div className="login-container">
        <div className="login-box">
          <div className="login-image">
            <img src={mouse} alt="mouse" />
          </div>
          <div className="login-form">
            <h2 style={{
              display: 'block',
              margin: 'auto',
              width: '136px',
              textAlign: 'center',
            }}>Log in</h2>
            <form onSubmit={handleSubmit}>
              <br />
              <div className="form-group">
                <input type="text" className="form-control" id="username" value={username} onChange={e => setUsername(e.target.value)} required placeholder="Username" />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} required placeholder="Password" />
              </div>
              <button type="submit" className="btn btn-primary">Log in</button>
            </form>
            <br />
            <div>Need help? Send us an email at <a href="mailto:support@sleepyrat.ai">support@sleepyrat.ai</a></div>
            <br />
            <br />
            <LinkContainer to="/signup">
              <div>Don't have an account? <button className="btn btn-primary btn-sm">Sign up</button></div>
            </LinkContainer>
            <br />
            <LinkContainer to="/forgot-password">
              <div>Forgot your password? <button className="btn btn-primary btn-sm">Reset password</button></div>
            </LinkContainer>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Login;