import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const token = new URLSearchParams(location.search).get('token');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_BASE_URL}/set-new-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, new_password: newPassword }),
      });
      const data = await response.json();
      setMessage(data.msg);
      if (response.ok) {
        setTimeout(() => navigate('/login'), 3000);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="limitmaxwidth">
        <div className="reset-password-container">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
            <div className="form-group">
            <input
                type="password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                placeholder="Enter new password"
            />
            </div>
            <button type="submit" className="btn btn-primary">
            Set New Password
            </button>
        </form>
        {message && <p className="message">{message}</p>}
        </div>
    </div>
  );
};

export default ResetPassword;