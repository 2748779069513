import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiCall } from '../utils';

interface Project {
  name: string;
  description: string;
  folders: Folder[];
}

interface Folder {
  name: string;
}

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    fetchProjectsAndFolders();
  }, []);

  const fetchProjectsAndFolders = async () => {
    apiCall('projects', {}, async (projects: Project[]) => {
      console.log("got projects:", projects)
      const projectsWithFolders = await Promise.all(projects.map(async (project) => {
        const folders = await fetchFoldersForProject(project.name);
        console.log("got folders for project", project.name)
        return {
          ...project,
          folders,
        };
      }));
      setProjects(projectsWithFolders);
    });
  };

  const fetchFoldersForProject = async (projectId: string): Promise<Folder[]> => {
    return new Promise((resolve) => {
      apiCall(`projects/${projectId}/folders`, {}, (folders: any) => {
        const formattedFolders = folders.map((name: string, index: number) => ({
          id: String(index),
          name: name,
        }));
        resolve(formattedFolders);
      });
    });
  };


  const createProject = async (projectName: string) => {
    apiCall('projects', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: projectName,
        description: '',
      }),
    }, () => {
      fetchProjectsAndFolders();
    });
  };

  const updateProject = async (projectId: string, updatedName: string) => {
    apiCall(`projects/${projectId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ new_name: updatedName }),
    }, () => {
      fetchProjectsAndFolders();
    });
  };

  const deleteProject = async (projectId: string) => {
    apiCall(`projects/${projectId}`, {
      method: 'DELETE',
    }, () => {
      fetchProjectsAndFolders();
    });
  };

  const fetchFolders = async (projectId: string) => {
    apiCall(`projects/${projectId}/folders`, {}, (folders: string[]) => {
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.name === projectId
            ? {
              ...project,
              folders: folders.map((name, index) => ({
                id: String(index),
                name,
              })),
            }
            : project
        )
      );
    });
  };

  const createFolder = async (projectId: string, folderName: string) => {
    apiCall(`projects/${projectId}/folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ folder_name: folderName }),
    }, () => {
      fetchFolders(projectId);
    });
  };

  const updateFolder = async (projectId: string, folderId: string, updatedName: string) => {
    apiCall(`projects/${projectId}/folders/${folderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ new_name: updatedName }),
    }, () => {
      fetchFolders(projectId);
    });
  };

  const deleteFolder = async (projectId: string, folderName: string) => {
    apiCall(`projects/${projectId}/folders/${folderName}`, {
      method: 'DELETE',
    }, () => {
      fetchFolders(projectId);
    });
  };

  return (
    <div style={{display: 'flex', flexDirection: 'row'}} className='py-4 limitmaxwidth'>
      <div className="col-12">
        <div className="row justify-content-left mb-3">
          <div className="col-7 text-left">
            <h4>Projects</h4>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-12">
            {projects.map((project) => (
              <div key={project.name}>
                <div className="d-flex justify-content-between align-items-center p-2 mb-2 border rounded" style={{ backgroundColor: '#5e5e5e', color: 'white' }}>
                  <div className="project-name col-10">
                    <i className="fa fa-layer-group pr-1"></i>
                    &nbsp;&nbsp;&nbsp;
                    {project.name.replace(/_/g, ' ')}
                  </div>
                  {/* <div className="project-description col-6">{project.description}</div> */}
                  <div className="col-2" style={{ textAlign: 'right' }}>
                    <button className="btn btn-danger" onClick={() => deleteProject(project.name)}>
                      <i className="fa fa-trash pr-1"></i>
                    </button>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-1"></div>
                  <div className="col-11">
                    {project.folders.map((folder) => (
                      <div key={folder.name} className="d-flex justify-content-between align-items-stretch p-2 mb-2 bg-light border rounded">
                        <Link to={`/projects/${project.name}/${folder.name}`} style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center', flex: '1' }} className="col-10">
                          <div className="folder-name" style={{ width: '100%' }}>
                            <i className="fa fa-folder pr-2"></i>&nbsp;&nbsp;&nbsp;
                            {folder.name.replace(/_/g, ' ')}
                          </div>
                        </Link>
                        <div className="col-2 text-right" style={{ textAlign: 'right' }}>
                          <button className="btn btn-danger" onClick={() => deleteFolder(project.name, folder.name)}>
                            <i className="fa fa-trash pr-1"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between align-items-center p-2 mb-2">
                      <div className="col-8">
                        <form onSubmit={(e) => {
                          e.preventDefault(); // Prevent the default form submission
                          const input = e.currentTarget.elements[0] as HTMLInputElement;
                          const newName = input.value;
                          if (newName) {
                            createFolder(project.name, newName);
                            input.value = ''; // Clear the input after submission
                          }
                        }}>
                          <input type="text" className="form-control" placeholder="New folder name" />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-between align-items-center p-2 mb-2">
              <div className="col-8">
                <form onSubmit={(e) => {
                  e.preventDefault(); // Prevent the default form submission
                  const input = e.currentTarget.elements[0] as HTMLInputElement;
                  const newName = input.value;
                  if (newName) {
                    createProject(newName);
                    input.value = ''; // Clear the input after submission
                  }
                }}>
                  <input type="text" className="form-control" placeholder="New project name" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2"></div>
    </div>
  );
};

export default Projects;