import config from './config';

export interface UserProfile {
  id: number;
  username: string;
  email: string;
  remaining_submissions: number;
  institute: string;
}

const fetchProfile = async (): Promise<UserProfile | null> => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${config.API_BASE_URL}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const profile: UserProfile = await response.json();
    return profile;
  } catch (error) {
    if (error instanceof Error) {
      console.error('There was a problem with the fetch operation:', error.message);
    } else {
      console.error('There was an unexpected problem with the fetch operation');
    }
    return null;
  }
};

const getRemainingSubmissions = async (): Promise<number> => {
  const profile = await fetchProfile();
  return profile ? profile.remaining_submissions : 0;
};

const apiCall = async (url: string, requestObject: any, func: any, parseJson = true) => {
  const token = localStorage.getItem('token');
  if (!requestObject.headers) {
    requestObject.headers = {};
  }
  requestObject.headers.Authorization = `Bearer ${token}`;
  try {
    const response = await fetch(`${config.API_BASE_URL}/${url}`, requestObject);
    if (!response.ok) {
      console.log(response.status);
      return response.status;
    }
    if (parseJson) {
      const json = await response.json();
      func(json);
    } else {
      const data = await response.blob();
      func(data);
    }
  } catch (error: any) {
    console.error('There was a problem with the fetch operation:', error);
  }
  return null;
}

const getHours = (seconds: number) => {
  return ((seconds / 3600) + "").substring(0, 4);
}


export { fetchProfile, getRemainingSubmissions, apiCall, getHours };