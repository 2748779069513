// UserContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface UserContextType {
    user: { username: string } | null;
    setUser: React.Dispatch<React.SetStateAction<{ username: string } | null>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }
  
  export const UserContext = createContext<UserContextType>({ user: null, setUser: () => {}, isLoading: true, setIsLoading: () => {} });
  
  export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<{ username: string } | null>(null);
    const [isLoading, setIsLoading] = useState(true);
  
    return (
      <UserContext.Provider value={{ user, setUser, isLoading, setIsLoading }}>
        {children}
      </UserContext.Provider>
    );
  };