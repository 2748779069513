import React from 'react';

const Testing: React.FC = () => {
  return (
    <div className="jumbotron">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2>Testing page</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testing;