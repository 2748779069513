import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import config from '../config';
import Terms from './Terms';

const Signup: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [institute, setInstitute] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  // Simple email validation
  const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }
    try {
      const response = await fetch(`${config.API_BASE_URL}/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, email, description, institute}),
      });
      const data = await response.json();
      console.log(data);
      if(data.msg.includes("already exists")){
        alert(data.msg);
        return;
      }
      if (response.ok) {
        // Login after signup
        try {
          const loginResponse = await fetch(`${config.API_BASE_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
          });
          if (loginResponse.ok) {
            const data = await loginResponse.json();
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('username', username);
            setUser({ username });
            navigate('/');
          } else {
            alert('Login failed');
          }
        } catch (error) {
          console.error('Error during login:', error);
        }
      } else {
        alert('Signup failed');
      }
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <div className="container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input type="text" className="form-control" id="username" value={username} onChange={e => setUsername(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input type="password" className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea className="form-control" id="description" value={description} onChange={e => setDescription(e.target.value)} placeholder="Tell us why you're signing up..." required />
        </div>
        <div className="form-group">
          <label htmlFor="institute">Institute</label>
          <input type="text" className="form-control" id="institute" value={institute} onChange={e => setInstitute(e.target.value)} required />
        </div>
        {/* Checkbox for accepting terms */}
        <div className="form-group">
          <input type="checkbox" required /> I accept the <a href="/terms">Terms of Service</a> of SleepyRat.
        </div>
        
        <br></br>
        <button type="submit" className="btn btn-primary">Sign Up</button>
      </form>
    </div>
  );
};

export default Signup;