import React, { useState } from 'react';
import config from '../config';

interface FaqItemProps {
    question: string;
    children: React.ReactNode;
  }
  
  const FaqItem: React.FC<FaqItemProps> = ({ question, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="faq-item mb-3">
        <h3 
          className="faq-question" 
          onClick={() => setIsOpen(!isOpen)}
          style={{ cursor: 'pointer' }}
        >
          {question}
          <span className="ml-2">{isOpen ? '▼' : '▶'}</span>
        </h3>
        {isOpen && <div className="faq-answer">{children}</div>}
      </div>
    );
  };


  const Faq: React.FC = () => {
    return (
        <div className="jumbotron">
            <div className="limitmaxwidth py-4 faq">
                <div className="row">
                    <div className="col-md-8">
                        <FaqItem question="Why SleepyRat?">
                            <p>SleepyRat increases research productivity by speeding up the scoring of sleep data. SleepyRat helps researchers to focus on what matters and avoid distractions. It specifically helps researchers with:</p>
                            <ul>
                                <li>Fast and accurate scoring of sleep data,</li>
                                <li>Increasing research reproducibility by standardising data formats and providing stable algorithms for scoring,</li>
                                <li>Providing off-the-shelf algorithm and freeing researchers from implementing every detail by themselves and therefore avoiding mistakes and saving precious research hours.</li>
                            </ul>
                        </FaqItem>

                        <FaqItem question="What happened to SleepLearning platform?">
                            <p>Due to lack of funding after dissolution of our research lab at ETH Zurich in June 2024, we agreed on this new independent solution after discussions with our active users. The goal of the SleepyRat is to provide continuous service and state-of-the-art models for sleep scoring of rodents. There is a parallel project to provide state-of-the-art scoring services for human sleep data.</p>
                        </FaqItem>

                        <FaqItem question="Should I pay for SleepyRat?">
                            <p>All users can open a new account and use SleepyRat free of charge for 12 hours of recordings per week.</p>
                            <p>Additionally, in order to cover the maintenance costs of our cloud solution and possible further developments, each lab can get unlimited access to our service for a yearly fee of 1000.- CHF. This annual subscription grants unlimited access (no weekly limit) to every member of your research lab. We encourage you to contribute and help us keeping the service up.</p>
                            <p>We do not plan to make profit from academic research labs.</p>
                            <p>Feel free to contact us at support@sleepyrat.ai for more information.</p>
                        </FaqItem>

                        <FaqItem question="Which algorithm is being used to score sleep data?">
                            <p>Similar to previous version on SleepLearning platform, architectural details of our method are described in the <a href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1006968">paper</a>. The algorithm will not be updated before notifying all users. The stability and consistency of the scoring algorithm is our most important objective.</p>
                        </FaqItem>

                        <FaqItem question="What kind of data format is required for scorings?">
                            <p>.edf files consist of 3 channels: EEG1, EEG2 and EMG which are accessible in that order.</p>
                            <p>.csv files contain two columns, each related to one of two human experts. The labels are given for each 4 second epoch of the corresponding EEG/EMG recording, and their meaning is:</p>
                            <ul>
                                <li><b>w</b> - Wake</li>
                                <li><b>n</b> - Non-rapid eye movement (NREM) or slow wave sleep (SWS)</li>
                                <li><b>r</b> - Rapid eye movement (REM)</li>
                                <li><b>1</b> - Wake artifact</li>
                                <li><b>2</b> - NREM artifact</li>
                                <li><b>3</b> - REM artifact</li>
                            </ul>
                        </FaqItem>

                        <FaqItem question="Dataset Download">
                            <p>We provide the full dataset used in our publication as a compressed <b>.zip</b> archive:</p>
                            <a href={`${config.API_BASE_URL}/resources/data.zip`} type="button" className="btn btn-light">
                                <i className="fas fa-cloud-download-alt"></i> Download
                            </a>
                        </FaqItem>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;